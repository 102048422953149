import React from 'react';
import Tools from '../components/Tools';
// const resources = process.env.PUBLIC_URL;

const Home = () => {
return (
		<Tools/>
);
};

export default Home;